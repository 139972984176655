import { __decorate } from "tslib";
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { AwsStorageClassesMap, StorageType } from '@flosum-backup/contracts';
let BackupStorageDeployPickControl = class BackupStorageDeployPickControl extends Vue {
    constructor() {
        super(...arguments);
        this.pickedStorage = null;
        this.pickedStorageClass = null;
    }
    get storagesList() {
        return this.storages
            .filter(storage => (this.hideLocal ? storage.type !== StorageType.Local : true))
            .map(storage => ({
            text: storage.name,
            value: storage.id,
        }));
    }
    get storageClassesList() {
        if (!this.pickedStorage) {
            return [];
        }
        switch (this.pickedStorageType) {
            case StorageType.AWS_S3:
                return [...AwsStorageClassesMap.entries()].map(([value, text]) => ({ text, value }));
        }
        return [];
    }
    get pickedStorageType() {
        return this.storages.find(storage => storage.id === this.pickedStorage)?.type || null;
    }
    setLocalAsPickedByDefault() {
        this.pickedStorage = this.storages.find(storage => storage.type === StorageType.Local)?.id || null;
    }
    onModelChanged() {
        this.$emit('changed', {
            id: this.pickedStorage,
            class: this.pickedStorageClass,
        });
    }
};
__decorate([
    Model('changed')
], BackupStorageDeployPickControl.prototype, "model", void 0);
__decorate([
    Prop()
], BackupStorageDeployPickControl.prototype, "storages", void 0);
__decorate([
    Prop({
        default: true,
    })
], BackupStorageDeployPickControl.prototype, "hideLocal", void 0);
__decorate([
    Watch('storages', { immediate: true, deep: true })
], BackupStorageDeployPickControl.prototype, "setLocalAsPickedByDefault", null);
__decorate([
    Watch('pickedStorage', { deep: true }),
    Watch('pickedStorageClass', { immediate: true })
], BackupStorageDeployPickControl.prototype, "onModelChanged", null);
BackupStorageDeployPickControl = __decorate([
    Component({})
], BackupStorageDeployPickControl);
export default BackupStorageDeployPickControl;
