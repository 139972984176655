import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { AwsStorageClass, StorageType, } from '@flosum-backup/contracts';
import VueModal from '@/classes/vue-modal';
import BackupsApi from '@/services/api/backups.api';
let BackupDeleteModal = class BackupDeleteModal extends VueModal {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.modalResult = false;
        this.pickedStorage = null;
    }
    get storagesList() {
        return this.storages.map(storage => ({
            text: storage.name,
            value: {
                id: storage.id,
                type: storage.type,
            },
        }));
    }
    get storageClassesList() {
        if (!this.pickedStorage) {
            return [];
        }
        switch (this.pickedStorage.type) {
            case StorageType.AWS_S3:
                return [
                    {
                        text: 'Instant',
                        value: AwsStorageClass.Instant,
                    },
                    {
                        text: 'Flexible',
                        value: AwsStorageClass.Flexible,
                    },
                    {
                        text: 'Standard',
                        value: AwsStorageClass.Standard,
                    },
                ];
        }
        return [];
    }
    async onDelete() {
        try {
            this.validateStorageOptions();
            this.loading = true;
            await BackupsApi.delete(this.id, this.pickedStorage.id);
            this.$toast.success('Backup was successfully deleted');
            this.modalResult = true;
            this.close();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    onModalHidden() {
        this.resolved(this.modalResult);
    }
    validateStorageOptions() {
        if (!this.pickedStorage) {
            throw new Error('Pick a storage to save backup');
        }
        const pickedStorage = this.storages.find(storage => storage.id === this.pickedStorage.id);
        if (!pickedStorage) {
            throw new Error('Invalid storage');
        }
    }
};
__decorate([
    Prop()
], BackupDeleteModal.prototype, "storages", void 0);
__decorate([
    Prop()
], BackupDeleteModal.prototype, "id", void 0);
__decorate([
    Prop()
], BackupDeleteModal.prototype, "name", void 0);
BackupDeleteModal = __decorate([
    Component({})
], BackupDeleteModal);
export default BackupDeleteModal;
