import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { AwsTypeRestore, StorageType } from '@flosum-backup/contracts';
import VueModal from '@/classes/vue-modal';
import BackupsApi from '@/services/api/backups.api';
let BackupDownloadModal = class BackupDownloadModal extends VueModal {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.modalResult = false;
        this.pickedStorage = null;
        this.pickedRestoreType = null;
    }
    get storagesList() {
        return this.storages
            .filter(storage => storage.type !== StorageType.Local)
            .map(storage => ({
            text: storage.name,
            value: {
                id: storage.id,
                type: storage.type,
            },
        }));
    }
    get restoreTypes() {
        if (!this.pickedStorage) {
            return [];
        }
        switch (this.pickedStorage.type) {
            case StorageType.AWS_S3:
                return [
                    {
                        text: 'Bulk',
                        value: AwsTypeRestore.Bulk,
                    },
                    {
                        text: 'Expedited',
                        value: AwsTypeRestore.Expedited,
                    },
                    {
                        text: 'Standard',
                        value: AwsTypeRestore.Standard,
                    },
                ];
        }
        return [];
    }
    async onDownload() {
        try {
            this.validateStorageOptions();
            this.loading = true;
            const body = {
                storageId: this.pickedStorage.id,
                retrieveType: this.pickedRestoreType,
                organizationId: this.organizationId,
            };
            await BackupsApi.retrieve(this.id, body);
            this.$toast.success('Backup retrieve job successfully queued');
            this.modalResult = true;
            this.close();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    onModalHidden() {
        this.resolved(this.modalResult);
    }
    validateStorageOptions() {
        if (!this.pickedStorage) {
            throw new Error('Pick a storage to save backup');
        }
        const pickedStorage = this.storages.find(storage => storage.id === this.pickedStorage.id);
        if (!pickedStorage) {
            throw new Error('Invalid storage');
        }
        if (pickedStorage.type === StorageType.AWS_S3 &&
            (!this.pickedRestoreType || !Object.values(AwsTypeRestore).includes(this.pickedRestoreType))) {
            throw new Error('Invalid storage class');
        }
    }
};
__decorate([
    Prop()
], BackupDownloadModal.prototype, "storages", void 0);
__decorate([
    Prop()
], BackupDownloadModal.prototype, "id", void 0);
__decorate([
    Prop()
], BackupDownloadModal.prototype, "name", void 0);
__decorate([
    Prop()
], BackupDownloadModal.prototype, "organizationId", void 0);
BackupDownloadModal = __decorate([
    Component({})
], BackupDownloadModal);
export default BackupDownloadModal;
