import request from '@/request';
export default class SchedulersApi {
    static find(params) {
        return request
            .get('/schedulers', {
            params,
        })
            .then(({ data }) => data);
    }
    static delete(id) {
        return request.delete(`/schedulers/${id}`).then(() => undefined);
    }
}
