import { __decorate } from "tslib";
import { Vue, Component, Inject, Ref } from 'vue-property-decorator';
import BackupsList from '@/components/shared/BackupsList.vue';
import JobsList from '@/components/shared/JobsList.vue';
import SchedulersList from '@/components/shared/SchedulersList.vue';
import BackupUploadModal from '@/components/BackupUploadModal.vue';
import StoragesApi from '@/services/api/storages.api';
import BackupDownloadModal from '@/components/BackupDownloadModal.vue';
import BackupDeleteModal from '@/components/BackupDeleteModal.vue';
let BackupsIndexPage = class BackupsIndexPage extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.storages = [];
    }
    async mounted() {
        try {
            this.storages = await StoragesApi.findAll();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    createBackup() {
        this.$router.push({
            name: 'backups.new',
        });
    }
    async showLogs(query) {
        this.$router.push({
            name: 'backups.logs',
            query,
        });
    }
    openBackup(backup) {
        this.$router
            .push({
            name: 'backups.backup.objects',
            params: {
                backupId: `${backup.id}`,
            },
        })
            .catch(() => false);
    }
    uploadBackup(backup) {
        const availableStorages = this.storages.filter(storage => !backup.storages.find(backupStorage => backupStorage.id === storage.id));
        this.$modal
            .show(BackupUploadModal, {
            id: backup.id,
            name: backup.name,
            storages: availableStorages,
            organizationId: this.organizationId,
        })
            .then(result => {
            if (result) {
                this.backupsList.fetchBackups();
            }
        });
    }
    downloadBackup(backup) {
        this.$modal
            .show(BackupDownloadModal, {
            id: backup.id,
            name: backup.name,
            storages: backup.storages,
            organizationId: this.organizationId,
        })
            .then(result => {
            if (result) {
                this.backupsList.fetchBackups();
            }
        });
    }
    deleteBackup(backup) {
        this.$modal
            .show(BackupDeleteModal, {
            id: backup.id,
            name: backup.name,
            storages: backup.storages,
        })
            .then(result => {
            if (result) {
                this.backupsList.fetchBackups();
            }
        });
    }
};
__decorate([
    Inject()
], BackupsIndexPage.prototype, "organizationId", void 0);
__decorate([
    Ref()
], BackupsIndexPage.prototype, "backupsList", void 0);
BackupsIndexPage = __decorate([
    Component({
        components: { SchedulersList, JobsList, BackupsList },
    })
], BackupsIndexPage);
export default BackupsIndexPage;
