import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import SchedulersApi from '@/services/api/schedulers.api';
let SchedulersList = class SchedulersList extends Vue {
    constructor() {
        super(...arguments);
        this.TABLE_FIELD_MAP = {
            name: { label: 'Name' },
            nextRunDate: { label: 'Next Run Date' },
            isRunning: { label: 'Status', thStyle: { width: '160px' } },
            actions: { label: 'Actions', thStyle: { width: '80px' } },
        };
        this.pagination = {
            page: 1,
            limit: 25,
            total: 0,
        };
        this.loading = false;
        this.search = '';
        this.schedulers = [];
    }
    get localOffset() {
        return this.pagination.limit * (this.pagination.page - 1);
    }
    get tableFields() {
        const fields = [{ key: 'index', label: '#', thStyle: { width: '6%' } }];
        fields.push(...this.shownColumns.map(columnName => {
            return {
                key: columnName,
                ...this.TABLE_FIELD_MAP[columnName],
            };
        }));
        return fields;
    }
    onFindQueryChanged() {
        this.$nextTick(() => this.fetchSchedulers());
    }
    onRowSelected(rows) {
        return rows[0];
    }
    mounted() {
        this.fetchSchedulers();
    }
    async deleteScheduler(id) {
        try {
            if (await this.$confirm('Are you sure?')) {
                await SchedulersApi.delete(id);
                this.$toast.success('Scheduler successfully deleted');
                await this.fetchSchedulers();
            }
        }
        catch (error) {
            this.$toast.error(error);
        }
    }
    async fetchSchedulers() {
        try {
            this.loading = true;
            const result = await SchedulersApi.find({
                organizationId: this.organizationId,
                page: this.pagination.page,
                limit: this.pagination.limit,
                search: this.search,
            });
            this.pagination.total = result.total;
            this.schedulers = result.schedulers;
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop()
], SchedulersList.prototype, "organizationId", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], SchedulersList.prototype, "shownColumns", void 0);
__decorate([
    Watch('pagination.page', { deep: true }),
    Watch('search')
], SchedulersList.prototype, "onFindQueryChanged", null);
__decorate([
    Emit()
], SchedulersList.prototype, "onRowSelected", null);
SchedulersList = __decorate([
    Component({})
], SchedulersList);
export default SchedulersList;
