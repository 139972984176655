import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { AwsStorageClass, StorageType } from '@flosum-backup/contracts';
import VueModal from '@/classes/vue-modal';
import BackupsApi from '@/services/api/backups.api';
import BackupStorageDeployPickControl from '@/components/BackupStorageDeployPickControl.vue';
let BackupUploadModal = class BackupUploadModal extends VueModal {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.modalResult = false;
        this.pickedStorage = {
            id: null,
            class: null,
        };
    }
    async onUpload() {
        try {
            this.validateStorageOptions();
            this.loading = true;
            const body = {
                storageId: this.pickedStorage.id,
                storageClass: this.pickedStorage.class,
                organizationId: this.organizationId,
            };
            await BackupsApi.deploy(this.id, body);
            this.$toast.success('Backup deploy job successfully queued');
            this.modalResult = true;
            this.close();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    onModalHidden() {
        this.resolved(this.modalResult);
    }
    validateStorageOptions() {
        if (!this.pickedStorage) {
            throw new Error('Pick a storage to save backup');
        }
        const pickedStorage = this.storages.find(storage => storage.id === this.pickedStorage.id);
        if (!pickedStorage) {
            throw new Error('Invalid storage');
        }
        if (pickedStorage.type === StorageType.AWS_S3 &&
            (!this.pickedStorage.class || !Object.values(AwsStorageClass).includes(this.pickedStorage.class))) {
            throw new Error('Invalid storage class');
        }
    }
};
__decorate([
    Prop()
], BackupUploadModal.prototype, "storages", void 0);
__decorate([
    Prop()
], BackupUploadModal.prototype, "id", void 0);
__decorate([
    Prop()
], BackupUploadModal.prototype, "name", void 0);
__decorate([
    Prop()
], BackupUploadModal.prototype, "organizationId", void 0);
BackupUploadModal = __decorate([
    Component({
        components: { BackupStorageDeployPickControl },
    })
], BackupUploadModal);
export default BackupUploadModal;
